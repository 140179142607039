/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~swiper/swiper-bundle.css';
// @import 'swiper/less/navigation';
// @import 'swiper/less/pagination';

/* #244b84  label #4c8dff blaufosc #09244d groc daurat #e3bb54 fonsjor #0e284b gris fosc #414344*/

@font-face {   
  font-family: 'Evil Empire';    //This is what we are going to call
  src: url('assets/fonts/evil.otf');
}

ion-content::part(scroll) {
    touch-action: pan-y pinch-zoom;
    background-color: #000 !important;
  }
:root {background-color: #000 !important;}

ion-toolbar::part(scroll) { background-color: #000 !important;}
ion-button {font-weight: 800;}
ion-header { background-color: #000 !important;}
ion-footer { background-color: #000 !important;}
ion-content { background-color: #000 !important;}
ion-toolbar { background-color: #000 !important;}
ion-form { background-color: #000 !important;}
ion-select { background-color: #000 !important; color: white;}
ion-label {color:#8ea2c5 !important; font-weight: 500 !important; font-family:'Evil Empire';}
.fons {background-color: #000 !important;}

.logoppal {object-fit: contain; padding-left:25px; padding-right:25px;}
.logoppal * {max-height: 200px !important; height: 100px !important;}
.logoball {background-image: url('assets/images/logo-ball2.png'); background-size: contain; background-repeat: no-repeat;}
.logoboto {height: 60px; width:60px; margin:auto;}
.logosponsor {height: 60px; width:140px; margin:auto;}

.botohome { padding: 5px 1px; margin-right: 5px; margin-left: 5px; font-family:'Evil Empire'; font-weight: 300 !important; letter-spacing: 2px;}
.botoapp {background-color: white; color:#000;}

.iconboto {color:#a88d16}
.iconimgc10 { width: 10px; height: 10px; margin-right: 1px; object-fit: contain;}
.iconimgc20 { width: 25px; height: 25px; margin-right: 1px; object-fit: contain; margin:auto;}
.iconimg { width: 30px; height: 30px; margin-right: 10px; object-fit: contain;}
.iconimg2 { width: 40px; height: 40px; margin-right: 1px; object-fit: contain;}
.iconimg3 { width: 30px; height: 30px; margin-right: 10px; object-fit: contain; background-color: white;}
.iconimg5 { width: 50px; height: 50px; margin-right: 1px; object-fit: contain;}
.iconimg6 { width: 60px; height: 60px; margin-right: 1px; object-fit: contain;}
.iconimg6x5 { width: 60px; height: 50px; margin-right: 1px; object-fit: contain;}
.iconimg8 { width: 80px; height: 80px; margin-right: 1px; object-fit: contain;}
.iconimg10 { width: 100px; height: 100px; margin-right: 1px; object-fit: contain;}
.obligado { color: #43b4de;}

.scroll2 {
  flex-wrap: nowrap;
  overflow: scroll!important;
  //overflow-y: hidden;
}
.scroll3 {
    flex-wrap: nowrap;
    overflow-x: scroll!important;
    overflow-y: hidden;}
.scroll-items {
  //justify-content: flex-start; // changed
  flex-wrap: nowrap;
  overflow: scroll;
  overflow-x: auto;
  //overflow-x: auto !important;
  overflow-y: hidden;
  //white-space: nowrap;
}
.scroll-items ion-col { // added
   // display: flex;
   // flex-wrap: nowrap;
   overflow:hidden;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
.flexr {display: flex-row;}
.iflex {display: inline-table;}
.tablecell {display:table-cell;}
.tablerow {display:table-row;}
.W {background-color:green;}
.L {background-color:red;}
.D {background-color:orange;}

.mt3 {margin-top: 3px;}  
.mt10 {margin-top: 10px;}
  .mb10 {margin-bottom: 10px;}
  .ma {margin: auto;}
  .pt10 {padding-top: 10px;}
  .pb10 {padding-bottom: 10px;}
  .pt30 {padding-top: 30px;}
  .pb30 {padding-bottom: 30px;}
  .banner { width:100%; height: 200px;}
  .pl0 {padding-left: 0px;}
  .pr0 {padding-right: 0px;}
  .pl10 {padding-left: 10px;}
  .pr10 {padding-right: 10px;}
  .ml10 {margin-left: 10px;}
  .mr10 {margin-right: 10px;}
  .mr30 {margin-right: 30px;}
  .pad0 {padding:0;}
  .pad10 {padding:10px;}
.centrarflex button{
    display: flex;    
    align-items: center;    
    justify-content: center;
    text-align: center;     
}
.borderb {border-bottom: 2px solid black; }
.bordert {border-top: 2px solid black; }
.borderw {border:2px solid white;}

.blau button{ color:#244b84; }
.blau{color:#244b84;}
.bblau{background-color:#244b84;}
.bblau2{background-color:#3171e0;}
.blanc {color:#fff;}
.bblanc {background-color:#fff;}
.blaufosc{color:#09244d;}
.bblaufosc{background-color:#09244d;}
.bblaufluix {background-color:#43b4de}
.bverd {background-color:#2fd370}
.verd {color:#2fd370}
.bgroc {background-color:#e3bb54 }
.groc {color:#e3bb54;}

.titolblau {color:#fff; font-size: 16; font-weight: 600; text-align: center;}
.titolblanc {color:#244b84; font-size: 16; font-weight: 600; text-align: center;}
.titolboto {background-color:#43b4de; color:#244b84; font-weight: 600; text-align: center;
            font-size: x-large; padding-top: 10px; padding-bottom: 10px;}
.titolnegre {background-color:black; color:white; font-weight: 600; text-align: center;
  border-radius: 1rem; font-size: x-large; padding-top: 10px; }

// .fonsjor {background-color: #292b2c; border-radius: 20px;}
.fonsjor {background: linear-gradient(#4b4c4c, #171c1f); border-radius: 35px;}

.data {color:white; font-weight: 200;}
.titols {text-align: center;border-radius: 1rem; font-size: x-large; padding-top: 10px; padding-bottom: 10px; background-color: #e3bb54;}
.rodo {
    border-radius: 50%;
    border: 2px solid #f5f5f5;
}
.br25 {border-radius: 30px;}
.br15 {border-radius: 15px;}
.truncate {
    width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
.wrap {white-space: break-spaces;}
  .caducat {
      background-color: red;
  }
  .blanc { color:white;}
  .vermell {color: red;}
  .textcenter {text-align: center;}
  .fsize8 {font-size: 8px;}
  .fsize10 {font-size: 10px;}
  .fsize12 {font-size: 12px;}
  .fsize14 {font-size: 14px;}
  .fsize16 {font-size: 16px;}
  .fsize18 {font-size: 18px;}
  .fsize20 {font-size: 20px;}
  .fsize26 {font-size: 26px;}
  .fsize40 {font-size: 40px;}
  .fsize50 {font-size: 50px;}

  .negrita {font-weight: bold;}
  .scroll {display: inline-block;}
  .flex {width: 100%;overflow-x: scroll;position:relative;white-space: nowrap;}
  .result {color: white;font-size: 2rem; border:2px solid white;}
  .rel {position: relative;}
  .line15 {line-height: 1.5;}
  .mauto {margin: auto;}
.jcc {justify-content: center;}
.jcs {justify-content: space-around;}



  .fonsmarcador:after {
    content: ' ';
    background: rgba(0, 0, 0, 0.5); 
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0.6;}
.nummarcador {
  opacity: 99%;
  z-index: 10;
}
/* TABLAS */
.taula td,th {border-bottom: 1px solid #8ea2c5; padding: 2px; border-right: 1px solid #8ea2c5;}
.taula {width:100%;}

.checkb > .checkbox-icon {border-color:#43b4de !important; border-width:2px !important;}
/* FORMULARIOS */
form { background-color: #000 !important; color: white;}
form ion-item{ background-color: #000; color: white;border-radius: 15px; margin: 15px;}
form ion-input{ background-color: transparent !important; color: white;}
form ion-select{ background-color: transparent !important; color: white;}
form ion-option{ color: white;}
form ion-radio{ color: white;}
form ion-label{ color: #a5c1f3;}

/* RANKING */
.ranking {border-radius: 1rem; font-size: x-large;}
.rpos {    width: fit-content;  padding: 8px;}

/* MANAGER */
.manager { border-radius: 25px;  background-color: #0e284b;  color: white;  border: 2px solid white; margin-bottom: 2px;}

/* GRADIENTS */
.grad .button-native{
  background: linear-gradient(#a88d16, #e3bb54);
}
.grad2 {
  background: linear-gradient(#a88d16, #e3bb54);
}
.cgrad2 {background: -webkit-linear-gradient(#a88d16, #e3bb54); -webkit-background-clip: text; background-clip: text; -webkit-text-fill-color: transparent;
 fill: linear-gradient(#a88d16, #e3bb54);}

/* FORMAS */
.cono {
  width: 0px;
  height: 0px;
  border-right: 70px solid transparent;
  border-left: 70px solid transparent;
  border-top: 100px solid #000;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
}
.ribbon {
  width: 0px;
  height: 135px;
  border-left: 75px solid #000;
  border-right: 75px solid #000;
  border-bottom: 35px solid transparent;
  position: absolute;
  border-radius: 25px;
}
.pentagon {
  position: absolute;
  width: 54px;
  box-sizing: content-box;
  border-width: 50px 18px 0;
  border-style: solid;
  border-color: #09244d;
}
.pentagon:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  top: -85px;
  left: -18px;
  border-width: 0 45px 35px;
  border-style: solid;
  border-color: transparent transparent #09244d;
}
